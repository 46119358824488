<template>
  <!-- Notation -->
  <form @submit.prevent="onSubmit()">
    <div class="parameter-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <div class="title-with-button max-width-md">
            <h2>Paramètres</h2>
            <Btn v-if="previousNotation && isEditable" text="Reprendre le dernier choix" hollow color="primary"
              @click="openModal('modalPreviousNotation')" />
          </div>
        </Container>
      </div>

      <template v-if="!isEditable">
        <p class="padding">La notation ne peut pas être modifié, elle est en cours ou est réservé.</p>
      </template>
      <Section v-else>
        <Container>

          <SelectExtended id="saisie_ordre_global_uid" search required label="Ordre de saisie" optionKey="uid"
            optionValue="designation" apiEndpoint="dictionnaire/saisie/ordre_global" class="max-width-xs" />

          <Radio label="Chemin de notation" id="saisie_ordre_uid" required :items="notationPaths" />
          <div v-show="currentFormValues.saisie_ordre_uid === 'MANUEL'" class="input-block margin-bottom">
            <div class="label">Édition manuelle du chemin</div>
            <Btn text="Éditer le chemin" color="black" @click="openModal('personalizedPath')" hollow />
          </div>

          <Input id="surface_comptage" label="Surface de comptage" type="number" class="max-width-xxs" required
            input-after="m2" :options="{
              min: 0.01,
              step: 0.01,
            }" />
          <Input id="nb_grains_comptage_elementaire" label="Nombre de grain échantillon" type="number" required
            class="max-width-xxs" :options="{
              min: 0,
              step: 0.01,
            }" />

          <Radio inputStyle="inline" id="aveugle" label="Notation à l'aveugle" :items="[
            {
              label: `Oui`,
              value: true,
            },
            {
              label: `Non`,
              value: false,
            },
          ]" />
        </Container>
      </Section>
      <div class="view-footer" v-if="isEditable">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: { id: $route.params.id } }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>

  <Modal title="Reprendre le dernier choix&nbsp;?" :active="modal.modalPreviousNotation" :data="modalData"
    @modal-close="modal.modalPreviousNotation = false">
    <template v-slot:modal-body>
      <p>
        Voulez vous vraiment reprendre les paramètres de la notation
        précédente&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer>
      <Btn text="Annuler" @click="modal.modalPreviousNotation = false" />
      <Btn text="Reprendre" @click="handlePreviousNotation()" color="primary" />
    </template>
  </Modal>

  <Modal title="Éditer le chemin personnalisé" :active="modal.personalizedPath" :data="modalData"
    @modal-close="modal.personalizedPath = false" size="full">
    <template v-slot:modal-body>
      <MicroParcels :key="componentKey" v-if="microParcelsData" :microParcelsData="microParcelsData"
        :pathData="pathData" isEditablePath :displayBorders="false" :startingPoint="plan?.point_depart?.uid"
        @handle-path="handlePath" />
    </template>
    <template v-slot:modal-footer>
      <div id="info-text"></div>
      <Btn text="Annuler" @click="modal.personalizedPath = false" />
      <Btn :disabled="$store.state.backInTime.selection.data &&
        $store.state.backInTime.selection.data.length <= 1
        " text="Revenir en arrière" @click="back()" hollow color="primary" />

      <Btn :disabled="getFormattedPathData() &&
        !getFormattedPathData().some((p) => 'moving' in p)
        " text="Annnuler la sélection" @click="removePathSelection()" hollow />
      <Btn :disabled="getFormattedPathData() &&
        getFormattedPathData().some((p) => 'moving' in p)
        " text="Enregistrer" @click="handleSubmitPath()" color="primary" />
    </template>
  </Modal>
</template>

<script>
/*
eslint-disable
*/

import Btn from "@/components/base/Btn.vue";
import Input from "@/components/form/Input.vue";
import Section from "@/components/layout/Section.vue";
import Container from "@/components/layout/Container.vue";
import Radio from "@/components/form/Radio.vue";
import SelectExtended from "@/components/form/SelectExtended.vue";
import Modal from "@/components/layout/Modal.vue";
import MicroParcels from "@/components/micro-parcels/MicroParcels.vue";

export default {
  name: "ParameterSubview",
  components: {
    MicroParcels,
    Modal,
    SelectExtended,
    Radio,
    Container,
    Section,
    Input,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      trigramme_validation: this.yup.string().nullable(),
      chemin_personnalise: this.yup.string().nullable(),
      surface_comptage: this.yup.number().test('len', 'La surface de comptage doit être supérieur à 0', val =>  val > 0).required(),
      nb_grains_comptage_elementaire: this.yup.number().required(),
      date_validation: this.yup.date().nullable(),
      coin_depart_uid: this.yup.object().nullable(),
      saisie_ordre_uid: this.yup.string().required(),
      saisie_ordre_global_uid: this.yup.object().required(),
      aveugle: this.yup.object().nullable(),
    });

    const { isSubmitting, errors, currentFormValues } =
      this.formService.initFrom(validationSchema);
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values);
    });

    return {
      previousNotation: null,
      notation: {},
      notationPaths: [],
      niveauSaisie: [],
      modal: {
        modalPreviousNotation: false,
        personalizedPath: false,
      },
      modalData: {},
      takePreviousNotation: false,
      form: {
        aveugle: false,
      },
      plan: null,
      microParcelsData: null,
      pathData: [],
      componentKey: 0,
      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
      currentFormValues,
    };
  },

  mounted() {
    this.getNotation();
    this.getPreviousNotation();
    this.getDictionaryNotationPaths();
    this.getDictionaryNiveauSaisie();
    this.getDictionaryOrdreSaisie();
    this.getMicroParcelData();

    this.$store.commit("backInTime/clearSelection");
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data;
      this.modal[modal] = true;
    },

    getNotation() {
      this.emitter.emit("open-loader");

      this.fetchService
        .get(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`
        )
        .then((response) => {
          const currentNotation = response.data[0];
          this.notation = currentNotation;

          if (this.notation) {
            const schema = this.formService.populateShema(this.notation);

            if (this.notation.nb_grains_comptage_elementaire) {
              schema.nb_grains_comptage_elementaire = this.notation.nb_grains_comptage_elementaire;
            } else {
              try {
                schema.nb_grains_comptage_elementaire = JSON.parse(this.$store.getters['app/parameters']([
                  'NOMBRE_GRAINS_ECHANTILLON',
                ]).at(0).valeur)
              } catch (e) {
                console.log(e)
              }
            }

            if (this.notation.surface_comptage) {
              schema.surface_comptage = this.notation.surface_comptage;
            } else {
              try {
                schema.surface_comptage = JSON.parse(this.$store.getters['app/parameters']([
                  'SURFACE_COMPTAGE',
                ]).at(0).valeur)
              } catch (e) {
                console.log(e)
              }
            }

            if (this.notation.saisie_objet) {
              schema.saisie_objet_uid = this.notation.saisie_objet.uid;
            }

            if (this.notation.saisie_ordre_global) {
              schema.saisie_ordre_global_uid = {
                key: this.notation.saisie_ordre_global.uid,
                value: this.notation.saisie_ordre_global.designation,
              };
            } else {
              try {
                const ordre = JSON.parse(this.$store.getters['app/parameters']([
                  'NOTATION_ORDRESAISIE',
                ]).at(0).valeur)
                schema.saisie_ordre_global_uid = typeof (ordre) === 'object' && ordre
                  ? ordre
                  : { key: 'MVOE', value: 'Microparcelle / Variable / Organe / Echantillon' }
              } catch (e) {
                console.log(e)
              }
            }
            if (this.notation.saisie_ordre) {
              schema.saisie_ordre_uid = this.notation.saisie_ordre.uid;
            } else {
              try {
                const chemin = JSON.parse(this.$store.getters['app/parameters']([
                  'NOTATION_CHEMINDENOTATION',
                ]).at(0).valeur)
                schema.saisie_ordre_uid = typeof (chemin) === 'object' && chemin
                  ? chemin.key
                  : 'ARHORIZONTAL'
              } catch (e) {
                console.log(e)
              }
            }


            if (this.notation.chemin_personnalise) {
              this.pathData = JSON.parse(this.notation?.chemin_personnalise);

              this.saveInStore();
            }

            if (this.notation.aveugle) {
              schema.aveugle = this.notation.aveugle
            } else {
              schema.aveugle = false
            }



            this.formService.setFormValues(schema);
          }

          this.emitter.emit("close-loader");
        });
    },

    getPreviousNotation() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement`)
        .then((response) => {
          const notations = response.data.filter(
            (notation) =>
              notation.type.uid === "NOTATION" &&
              notation.id !== parseInt(this.$route.params.tid, 10)
          );

          if (notations.length >= 2) {
            const lastNotation = Math.max(
              ...notations.map((notation) => notation.id)
            );

            const previousNotation = notations.find(
              (notation) => notation.id === lastNotation
            );

            this.fetchService
              .get(
                `essai/${this.$route.params.id}/evenement/${previousNotation.id}/fiche/notation`
              )
              .then((notation) => {
                const data = notation.data[0];
                this.previousNotation = data;
              });
          }
        });
    },

    getMicroParcelData() {
      this.fetchService
        .get(`essai/${this.$route.params.id}`)
        .then((response) => {
          this.plan = response.data.plan;

          this.fetchService
            .get(
              `essai/${this.$route.params.id}/plan/${this.plan.id}/microparcelle`,
              {
                sort: "x.ASC,y.ASC",
                limit: 0,
              }
            )
            .then((re) => {
              this.microParcelsData = re.data;
            });
        });
    },

    getDictionaryNotationPaths() {
      this.fetchService
        .get("dictionnaire/saisie/ordre", {
          sort: "designation.ASC",
        })
        .then((response) => {
          this.notationPaths = response.data;

          this.notationPaths.map((notationPath) => {
            // eslint-disable-next-line
            notationPath.label = notationPath.designation;
            // eslint-disable-next-line
            notationPath.value = notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.designation;
            // eslint-disable-next-line
            delete notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.id;

            return notationPath;
          });
        });
    },

    getDictionaryOrdreSaisie() {
      this.fetchService
        .get("dictionnaire/saisie/ordre_global", {
          sort: "id.desc",
        })
        .then((response) => {
          this.ordreSaisies = response.data;

          this.ordreSaisies.map((notationPath) => {
            // eslint-disable-next-line
            notationPath.label = notationPath.designation;
            // eslint-disable-next-line
            notationPath.value = notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.designation;
            // eslint-disable-next-line
            delete notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.id;

            return notationPath;
          });
        });
    },

    getDictionaryNiveauSaisie() {
      this.fetchService
        .get("dictionnaire/saisie/objet", {
          sort: "id.desc",
        })
        .then((response) => {
          this.niveauSaisie = response.data;

          this.niveauSaisie.map((notationPath) => {
            // eslint-disable-next-line
            notationPath.label = notationPath.designation;
            // eslint-disable-next-line
            notationPath.value = notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.designation;
            // eslint-disable-next-line
            delete notationPath.uid;
            // eslint-disable-next-line
            delete notationPath.id;

            return notationPath;
          });
        });
    },

    handlePath(path) {
      this.pathData = path;
      this.saveInStore();
    },

    handlePreviousNotation() {
      this.takePreviousNotation = true;
      this.handleSubmit(this.previousNotation);
    },

    handleSubmit(values) {
      this.emitter.emit("open-loader");

      values.saisie_objet_uid = 'PARCELLE'

      if (
        values.saisie_ordre_uid === "MANUEL" &&
        (values.chemin_personnalise === null && this.notation.chemin_personnalise === null)
      ) {
        this.emitter.emit("alert", {
          type: "error",
          content: "Le chemin personnalisé n’a pas été renseigné.",
        });
        this.emitter.emit("close-loader");
        return null;
      }

      if (this.takePreviousNotation) {
        // eslint-disable-next-line
        values.id = this.notation.id;
        // eslint-disable-next-line
        values.coin_depart_uid = values.coin_depart?.uid;
        // eslint-disable-next-line
        delete values.saisie_depart;
        // eslint-disable-next-line
        values.saisie_ordre_uid = values?.saisie_ordre?.uid;
        // eslint-disable-next-line
        delete values.saisie_ordre;
        // eslint-disable-next-line
        values.saisie_objet_uid = values?.saisie_objet?.uid;
        // eslint-disable-next-line
        delete values.saisie_objet;
        // eslint-disable-next-line
        values.saisie_ordre_global_uid = values?.saisie_ordre_global?.uid;
        // eslint-disable-next-line
        delete values.saisie_ordre_global;
        this.pathData = JSON.parse(values?.chemin_personnalise)
        this.componentKey += 1
      } else {
        // eslint-disable-next-line
        values.coin_depart_uid = values.coin_depart_uid?.key;
        // eslint-disable-next-line
        values.saisie_ordre_global_uid = values.saisie_ordre_global_uid?.key;
        // eslint-disable-next-line
        values.chemin_personnalise = this.notation.chemin_personnalise;
      }

      this.fetchService
        .put(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}`,
          values
        )
        .then(() => {
          this.getNotation();

          if (this.takePreviousNotation) {
            this.emitter.emit("alert", {
              type: "success",
              content:
                "Les paramètres ont bien été restaurés vers la dernière version.",
            });

            this.takePreviousNotation = false;
            this.modal.modalPreviousNotation = false;
          } else {
            this.emitter.emit("alert", {
              type: "success",
              content: "Les paramètres ont bien été modifiés.",
            });
          }

          this.emitter.emit("close-loader");
        });
    },

    handleSubmitPath() {
      this.emitter.emit("open-loader");

      this.fetchService
        .put(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}`,
          {
            saisie_objet_uid: 'PARCELLE',
            chemin_personnalise:
              this.pathData && this.pathData.length > 0
                ? JSON.stringify(this.pathData)
                : null,
          }
        )
        .then((res) => {
          this.notation.chemin_personnalise = res.data.chemin_personnalise;

          this.emitter.emit("alert", {
            type: "success",
            content: "Le chemin personnalisé a bien été mise à jour.",
          });

          this.modal.personalizedPath = false;
          this.emitter.emit("close-loader");
        });
    },

    back() {
      this.emitter.emit("open-loader");

      const pathDataFromStore = this.$store.state.backInTime.selection.data;
      let pathData;
      /**
       * Si le nombre d'actions est égale à deux
       * On ne prend pas l'avant-dernière, mais la dernière (donc le chemin de base)
       */
      if (pathDataFromStore.length === 2) {
        pathDataFromStore.pop();
        // eslint-disable-next-line
        pathData = pathDataFromStore[0];
      }
      /**
       * Au mounted, le chemin existant a été ajouté au store
       * Si l'utilisateur a fait plus d'une action,
       * on supprime la dernière action pour toujours garder celle de base
       */
      if (pathDataFromStore.length > 2) {
        pathData = pathDataFromStore[pathDataFromStore.length - 2];
        pathDataFromStore.pop();
      }

      this.pathData = pathData;
      this.componentKey += 1;

      this.emitter.emit("close-loader");
    },

    saveInStore() {
      this.$store.commit("backInTime/updateSelection", [...this.pathData]);
    },

    /**
     * 'Annuler la sélection' button
     */
    removePathSelection() {
      const selection = this.getFormattedPathData().find((p) => "moving" in p);

      delete selection.moving;
    },

    getFormattedPathData() {
      if (this.pathData) {
        if (typeof this.pathData === "string") {
          return JSON.parse(this.pathData);
        }
        if (typeof this.pathData === "object") {
          return this.pathData;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
// Modal info text

#info-text {
  position: relative;
  flex-basis: 100%;
}
</style>
